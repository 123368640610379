/* navbar */
#navbar {
  background-color: rgba(129, 139, 126);
  padding-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
  color: rgb(247, 238, 227);
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  font-weight: bold;
}

.navbar-link {
  cursor: pointer;
  padding: 0rem 3rem 0rem 3rem;
}

@media (max-width: 768px) {
  #navbar {
    justify-content: center;
    padding-top: 1.5rem;
  }

}



/* about */
#about {
  background-color: rgba(129, 139, 126);
  padding-top: 0.5rem;
  padding-bottom: 5rem;
}

#ivan-header{
  color: rgb(247, 238, 227);
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  font-size: 50px;
  padding-left: 1rem;
  position: relative;
  animation: mymove 2s;

  font-family: "Big Shoulders Inline Text", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

  /* font-family: "Major Mono Display", monospace;
  font-weight: 400;
  font-style: normal; */
  
  /* font-weight: 100; */
  /* font-style: normal; */
}

#about-ivan{
  display: flex;
  justify-content: space-evenly;
}

#ivan-information{
  width: 50%;
  color: rgb(247, 238, 227);
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  /* font-weight: 400; */
  font-style: normal;
}

#personal-stylist{
  font-size: 25px;
  padding: 0rem 2rem 1rem 2rem;
  font-weight: bold;
}

#about-paragraph{
  font-size: 21px;
  padding: 4rem 2rem;
}

#phone-number {
  font-size: 20px;
  padding: 0rem 2rem 0rem 2rem;
  font-weight: bold;
}

#ivan-photo-box {
  width: 50%;
  display: flex;
  justify-content: center;
}

.ivan-photo {
  max-width: 85%;
  height: auto;
  border-top-left-radius: 370px;
  border-top-right-radius: 370px;
}


@keyframes mymove {
  from {left: 300px;}
  to {left: 0px;}
}


@media (max-width: 840px) {
  #ivan-photo-box {
    width: 100%;
  }
  .ivan-photo {
    max-width: 94%;
  }

  #about {
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
  }
  #about-paragraph{
    padding:2rem;
  }

  #about-ivan {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
  #ivan-information{
    width: 100%;
  }
}



/* services */
#services {
  background-color: rgb(251, 239, 224);
  padding-top: 2rem;
}

#services h1 {
  font-family: "Big Shoulders Inline Text", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

  /* font-family: "Major Mono Display", monospace;
  font-weight: 400;
  font-style: normal; */

  font-size: 50px;
  padding-left: 1rem;
  padding-bottom: 1.5rem;
}

#services-photos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#services-photos img {
  width: 25%;
}

#services-list {
  margin: auto;
  background: #ffffff91;
  max-width: 800px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 3rem;
}

.service-header {
  color: rgb(93, 102, 90);
  text-transform: uppercase;
  font-weight:800;
  font-size:1.3rem;
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
}
.service-header1{
  color: rgb(93, 102, 90);
  text-transform: uppercase;
  font-weight:800;
  font-size:1.3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.services-table{
  width: 80%;
  margin-left: auto; 
  margin-right: auto;
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-size: large;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.services-table td{
padding-left: 2.8rem;
}

.price{
  text-align: center;
}

@media (max-width: 768px) {
  #services {
    padding-top: 0.1rem;
  }

  .services-table{
    width: 100%;
  }

  .services-table td{
    padding-left: 0rem;
    }

  #services-photos img {
    width: 50%;
    /* Adjust for smaller screens */
  }

  #services-list {
    margin: 1rem;
  }
}



/* location */
#location {
  background-color: rgb(164, 96, 96);
  padding-top: 2rem;
  padding-bottom: 3rem;
}

#location h1 {
  font-family: "Big Shoulders Inline Text", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

  /* font-family: "Major Mono Display", monospace;
  font-weight: 400;
  font-style: normal; */

  font-size: 50px;
  color: rgb(247, 238, 227);
  padding-bottom: 1rem;
  padding-left: 1rem;
}

#location-info {
  display: flex;
  justify-content: space-evenly;
  color: rgb(247, 238, 227);
}

#location-info h2 {
  font-size: xx-large;
}

#address {
  padding: 1rem;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

@media (max-width: 840px) {
  #location {
    padding-top: 0.1rem;
    padding-bottom: 0rem;
  }

  #location h1 {
    padding-bottom: 0rem;
  }

  #address {
    padding: 0rem;
  }

  #location-info {
    flex-direction: column-reverse;
    text-align: center;
  }

  #location-info iframe {
    width: 100%;
    height: 350px;
  }
}



/* Footer */
.footer {
  background-color: rgb(100, 105, 138);
  padding-top: 2.5rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.footer p {
  color: rgb(247, 238, 227);
  text-align: center;
  
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.fa-instagram {
  color: rgb(247, 238, 227);
  font-size: 35px;
}